<template>
  <FullScreenDialog
    v-model="enablingTypeDialog"
    title="Tipo di abilitazione"
    :routeFather="pathToGoBack"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in enablingTypesTab"
          :key="tab.key"
          @click="$router.push({ name: tab.routeName }).catch(() => {});"
        >{{ tab.title }}</v-tab
        >
      </v-tabs>
    </template>

    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in enablingTypesTab" :key="tabItem.key">
          <v-card flat>
            <v-card-text class="pa-0">
              <router-view></router-view>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-slot:footer-actions>
      <div>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          @click="$router.push({path: pathToGoBack})"
        >Annulla</v-btn>
        <v-btn
          text
          color="default"
          :disabled="!formValid"
          @click="submitForm"
        >Salva</v-btn>
      </div>
    </template>

<!-- KEEP COMMENT - ONEWARE-562     
  <template v-slot:header-actions>
      <div>
        <StandardMenu
          close-on-content-click
        >
          <template v-slot:activator="{on, attrs}">
            <v-btn icon v-on="on" v-bind="attrs">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-printer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Stampa</v-list-item-content>
              </v-list-item>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon color="error">mdi-backspace-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="error--text">Resetta</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </StandardMenu>
      </div>
    </template> -->
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardMenu from '@/components/common/StandardMenu.vue';
import enablingTypesService from '@/services/enablingTypes/enablingTypes.service.js'
import enablingTypeForm from '@/services/enablingTypes/enablingType.form.js'

export default {
  name: "EnablingTypesRegistryEditForm",
  components: {
    FullScreenDialog,
    StandardMenu,
  },
  data: function () {
    return {
      id: undefined,
      tab: null,
      formValid: false,
      loadingEnablingType: false,
      enablingTypeDialog: this.openDialog,
      enablingTypesTab: this.tabs,
      enablingType: undefined,
    };
  },
  mounted() {
    this.id = this.$route.params.id
    if(this.id) {
      this.fetchEnablingType()
    }

    this.changeTabBasedOnRoute(this.$route.name)

    let self = this
    enablingTypeForm.on('update', function(data) {
      self.enablingType = data.enablingType
    })

    self.valid = enablingTypeForm.valid
    enablingTypeForm.on('valid-change', function(valid) {
      self.formValid = valid
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/registries",
    },
    tabs: {
      type: Array,
      default: function () {
        return [
          {
            key: 0,
            title: "Generale",
            routeName: "EnablingTypesGeneralEditFormTab",
          }
        ];
      },
    },
  },
  methods: {
    changeTabBasedOnRoute(routeName) {
      for(let i = 0; i < this.tabs.length; i++) {
        if(this.tabs[i].routeName == routeName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      enablingTypesService.update(this.enablingType).then((result) => {
        this.$router.push({path: this.pathToGoBack})
      })
    },
    fetchEnablingType() {
      this.loadingEnablingType = true
      enablingTypeForm.resetEnablingType()
      enablingTypesService.get(this.id).then((enablingType) => {
        this.loadingEnablingType = false
        enablingTypeForm.updateObject(enablingType)
      })
    }
  },
  watch: {
    openDialog(newVal) {
      this.enablingTypeDialog = newVal;
    },
  },
};
</script>