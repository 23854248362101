import eventsHandler from '@/services/common/eventsHandler.js'

class enablingTypeForm extends eventsHandler {
  constructor() {
    super()
    this._enablingType = {}
    this._valid = false
  }

  get enablingType() {
    const enablingType = {
      ...this._enablingType
    }
    return enablingType
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._enablingType.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetEnablingType() {
    this._enablingType = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {enablingType: {}})
  }

  updateField(key, value) {
    this._enablingType[key] = value
    this._executeCallbacksOf('update', {enablingType: this._enablingType})
    this._executeCallbacksOf('update-' + key, {key: this._enablingType[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(enablingType) {
    this._enablingType = enablingType
    this._executeCallbacksOf('update', {enablingType: this._enablingType})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new enablingTypeForm()